<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else class="content-reward">
    
      <RewardDetailPanel
        :detail="privilegeDetail"
        :branch="branchList"
        :addressFlag="addressFlag"
        :id="parseInt(id)"
        :totalPoint="totalPoint"
        :addressList="addressList"
      />
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import RewardDetailPanel from "@/components/reward/detail/RewardDetailPanel";
export default {
  components: {
    Breadcrumb,
    RewardDetailPanel,
    OtherLoading
  },
  data() {
    return {
      breadcrumbList: [
        {
          text: "รายละเอียดของรางวัล",
          to: "/reward"
        }
      ],
      privilegeDetail: {},
      id: this.$route.params.rewardId,
      isLoading: true,
      branchList: [],
      addressFlag: false,
      totalPoint: 0,
      addressList: {}
    };
  },
  async created() {
    await this.getRedeemInfo();
    await this.getBranch();
    await this.getPointHistoryLog();
  },
  methods: {
    async getRedeemInfo() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/redeem/info/${this.id}`)
        .then(
          async data => {
            this.isLoading = false;
            if (data.result == 1) {
              this.privilegeDetail = data.detail;
              if (data.detail.pick_up_type_id === 2) {
                await this.checkAddress();
              }
            }
          },
          error => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
              console.log(error);
            }
          }
        );
    },
    async checkAddress() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/User/VerifyAddress`)
        .then(async data => {
          if (data.result === 1) {
            this.addressFlag = data.detail.has_address;
            this.addressList = {
              home_address: data.detail.home_address,
              district: data.detail.district,
              province: data.detail.province,
              subdistrict: data.detail.subdistrict,
              zip_code: data.detail.zip_code
            };
          }
        });
    },
    async getBranch() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/Redeem/get_branch_list`)
        .then(
          async data => {
            this.isLoading = false;
            if (data.result == 1) {
              this.branchList = data.detail;
            }
          },
          error => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
              console.log(error);
            }
          }
        );
    },
    async getPointHistoryLog() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/user/PointLogAllBranch`)
        .then(data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.totalPoint = data.detail.last_point;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.content-page {
  // margin-top: 80px;
  margin-bottom: 100px;
  //   min-height: 100vh;
}
</style>
